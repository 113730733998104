@tailwind base;
@tailwind components;

.draggable {
  top: auto !important;
  left: auto !important;
}

label {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

:focus {
  outline: none !important;
}

body {
  @apply bg-gray-100;
}

.btn {
  @apply rounded-lg text-white px-5 py-2 text-center hover:shadow hover:shadow-inner transition duration-150 ease-in-out text-center cursor-pointer select-none
}

.btn-primary {
  @apply bg-blue-500 hover:bg-blue-800
}

.btn-green {
  @apply bg-green-500 hover:bg-green-600
}

.btn-purple {
  @apply bg-fuchsia-500 hover:bg-fuchsia-600
}

.btn-danger {
  @apply bg-red-600 hover:bg-red-800
}

.btn-warning {
  @apply bg-yellow-200 hover:bg-yellow-300 text-black
}

.btn-gray {
  @apply bg-gray-200 hover:bg-gray-300 text-black
}

.card {
  @apply shadow-sm p-5 mb-8 bg-white rounded-sm
}

.card.disabled {
  @apply opacity-50 pointer-events-none
}

.badge-green {
  @apply inline-block bg-blue-500 text-white text-sm px-2 rounded
}

.badge-primary {
  @apply inline-block bg-purple-600 text-white text-sm px-2 rounded
}

.badge-secondary {
  @apply inline-block bg-gray-600 text-white text-sm px-1 rounded
}

.form-text {
  @apply border w-full p-2 mb-2 rounded block text-sm
}

.upgrade-color {
  @apply bg-yellow-500 text-white
}

.upgrade-side-badge {
  @apply badge-secondary align-middle upgrade-color cursor-pointer
}

.upgrade-side-badge {
  @apply badge-secondary align-middle upgrade-color cursor-pointer
}

.animation-button-selector {
  @apply inline-flex border-[1px] w-24 text-center justify-center rounded m-2 hover:bg-blue-500 hover:text-white cursor-pointer border-black
}

.tab-item {
  @apply block px-3 p-2 mx-1 cursor-pointer
}

.tab-selected {
  @apply py-2 px-3 bg-blue-500 rounded-full mx-1 text-white shadow
}

.inner-tab-item {
  @apply p-5 cursor-pointer
}

.inner-tab-selected {
  @apply btn btn-primary cursor-pointer
}

.font-selected {
  @apply border-purple-500 border-2
}
.phone {
  border-radius: 60px;
}

.help {
  @apply block text-gray-500 text-xs
}

.modal-btn-primary {
  @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none /*sm:*/ml-3 /*w-full*/ sm:w-auto sm:text-sm min-w-[30%]
}

.modal-btn-danger {
  @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none /*sm:*/ml-3 /*w-full*/ sm:w-auto sm:text-sm
}

.modal-btn-warning {
  @apply btn-warning inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none /*sm:*/ml-3 /*w-full*/ sm:w-auto sm:text-sm
}

.modal-btn-cancel {
  @apply inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 /*sm:*/ml-3 /*w-full*/ sm:w-auto sm:text-sm
}

.modal-icon-danger {
  @apply bg-red-100 text-red-600
}

.modal-icon-warning {
  @apply bg-yellow-100 text-yellow-600
}

.global-message {
  @apply absolute top-0 left-0 justify-center items-center flex w-full h-[50px] z-50
}

.global-message.success {
  @apply bg-green-600 text-white
}

.global-message.error {
  @apply bg-red-100 text-red-600
}

.global-message.warning {
  @apply bg-yellow-100 text-yellow-600
}

.badge

.site-noti {
  text-align: center;
  line-height: 50px;
  min-width: 100%;
  overflow: hidden;
  height: 50px;
  top: 0;
  color: #fff;
  z-index: 5000
}

.site-noti.success {
  background: #56bc8a;
  color: #fff
}

.site-noti.confirm {
  background: #ededb3;
  color: #000
}

.site-noti.confirm a {
  font-weight: 700
}

.site-noti a {
  text-decoration: underline
}


.social-connect-button {
  display: block;
  text-align: center;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  padding: 6px;
  /* height: 39px; */
  text-decoration: none !important;
  text-transform: uppercase;
  color: #1E2238;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background .15s ease;
}

.alert-warning {
  @apply bg-amber-100 text-yellow-800 p-4
}

.context-menu {
  @apply absolute right-5 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10
}

.context-bottom {
  @apply bottom-0
}

.context-menu-item {
  @apply text-gray-700 block px-4 py-2 text-sm flex items-center cursor-pointer
}

.context-menu-item .icon {
  @apply w-4 h-4 mr-2;
}

.menu-item {
  @apply p-2 block
}
.menu-selected {
  @apply bg-blue-800 text-white w-full rounded
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(150%);
}

input:checked + .toggle-bg {
  @apply bg-green-500
}

@tailwind utilities;

.pickr .pcr-button {
  width:100% !important;
}

.spinner.small {
  position: absolute;
  top: -15px;
  /* left: -13px; */
  width: 60px;
}

.phone-preview-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}


.picker {
  position: relative;
}

.swatch {
  height: 30px;
  border-radius: 4px;
  border:1px solid #EEE;
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  padding:10px;
  background:white;
  z-index: 500;
}


.picker__swatches {
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
}

.picker__swatch {
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.cropper-modal {
  opacity: .5 !important;
}
.uppy-ImageCropper-controls {
  botton: 8px !important;
}

.uppy-DashboardContent-save {
  padding: 10px 14px !important;
  color: #fff !important;
  font-size: 14px !important;
  line-height: 1 !important;
  background-color: #1bb240 !important;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  object-fit: none !important;
}

.uppy-Dashboard-Item-previewInnerWrap {
  background:none !important;
}

.color-input {
  border: 1px solid #d1d0d0;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  padding: 2px 5px;
  display: inline-flex;
}

.color-input input {

}
